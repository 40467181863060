import React from 'react';

import Evry from '@assets/evry.svg';
import { LANG_US_EN } from '@/src/common/consts/lang';
import Layout from '../../components/layout';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import ImageText from '../../components/sections/imageText/imageText';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import TestimonialGridST from '../../components/staticSections/TestimonialGridST/TestimonialGridST';
import Table from '../../components/sections/table/table';
import FaqST from '../../components/staticSections/FaqST/FaqST';
import CtaST from '../../components/staticSections/CtaST/CtaST';

const LANG = LANG_US_EN;
const ONBOARDING_URL = 'https://app.adjust.com/idl8afl?campaign=landingpage';

const IndexPage = () => {
  return (
    <Layout
      lang="us"
      topHeader
      headerWitdh="header"
      headerStyle="flex--space-between"
      text="In-network with Evry Health"
      showFooter
      headerImage={
        <img className="logo--md margin-left--xs flex" alt="Evry Health" src={Evry} />
      }
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
      <Seo title="Evry Health" language="en" />
      <HeroCTA
        dataTheme="ice-blue"
        idCta="herobutton"
        imagedata4
        dataSection="hero_CTA"
        alt="Evry Health"
        title="Treat joint and back pain from home"
        titleClassName=""
        text="Download the Joint Academy app to your phone and get started with a personalized exercise program today."
        textClassName=""
        downloadButton
        downloaddataGoal="Onboarding Viewed"
        downloadhref={ONBOARDING_URL}
        downloadclassname="margin-top--md btn btn--fullwidth btn--lg btn--primary"
        downloadtext="GET STARTED"
        tagHeadline="Get a text to download the app"
        smsTag
        smsTagClassName="display--md"
        dataToken="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhZmZpbGlhdGVfbmFtZSI6IkV2cnkgSGVhbHRoY2FyZSBJbmMgKDAwMTNYMDAwMDJVR05XblFBUCkiLCJpYXQiOiIyMDE5LTEwLTI1VDExOjI4OjEwLjQ5NloiLCJqdGkiOiIyZmRhZGNmNS01NjZlLTQ4YmQtYTY3OC03YzVlNGIwODI0MzAifQ.v4AMz8vJtWsY1pLUEK4EFPagPlaURlY6t6XDqF9wUoE"
      />
      <ImageText
        imgSize="col--lg-4"
        Filename="evry.png"
        alt="Evry Health"
        Position=""
        title="In-network with Evry Health"
        text="Joint Academy is a covered benefit under most Evry Health plans at no cost to you. Download the Joint Academy app to confirm your coverage or contact support for further assistance."
        download
        downloaddataGoal="Onboarding Viewed"
        downloadhref={ONBOARDING_URL}
        downloadclassname="btn btn--primary btn--md"
        downloadtext="get started"
      />
      <GetStartedST
        lang={LANG}
        renderDownloadButton
        ctaButtonText="Get Started"
        ctaDataGoal="Onboarding Viewed"
        ctaHref={ONBOARDING_URL}
      />
      <BgCardsTextST dataTheme="light" lang={LANG} />
      <SocialProofFeedST lang={LANG} />
      <TestimonialGridST lang="us_en_v2" />
      <LicensedPtSliderST
        lang={LANG}
        downloaddataGoal="Onboarding Viewed"
        downloadhref={ONBOARDING_URL}
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Get started today"
      />
      <Table
        title="Joint Academy vs. traditional treatment"
        text="Joint Academy is a digital treatment for chronic joint pain that might be beneficial you you compared to traditional face-to-face physical therapy available at clinics."
        images={[
          {
            alt: 'Price table desktop',
            Filename: 'price-table-large-free.png',
            className: 'display--sm',
          },
          {
            alt: 'Price table mobile',
            Filename: 'price-table-small-free.png',
            className: 'hide--sm',
          },
        ]}
        ctaButtonText="GET STARTED TODAY"
        ctaDataGoal="Onboarding Viewed"
        ctaHref={ONBOARDING_URL}
      />
      <OutcomeST lang={LANG} />

      <FaqST lang={LANG} />
      <CtaST
        lang={LANG}
        ctaDataGoal="Calendly Viewed"
        ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        ctaButtonText="Schedule Call"
      />
    </Layout>
  );
};

export default IndexPage;
